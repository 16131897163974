<template>
	<div class="hello">
	</div>
</template>

<script>
const WavDecoder = require("wav-decoder");
const Pitchfinder = require("pitchfinder");

let frequenciesRes = [];
export default {
	name: 'HelloWorld',
	props: {
		msg: String
	},
	data() {
		return {
			// frequenciesRes: [],
		}
	},
	mounted() {
		console.log('计算音高值~~~~~~~~~');
		window.disposeFile = async function (url) {
			try {
				let response = await fetch(url);
				return response.arrayBuffer();
			} catch (err) {
				console.error(err)
			}
		}

		/**
		 * 
		 * @param {*} info 音频分段列表
		 * @param {*} tempo BPM中默认为120
		 * @param {*} quantization 每拍采样，默认为4(即第16个音符)
		 * 
		 */
		window.handleFrequencies = async function (info, tempo = 120, quantization = 4) {
			console.log('要解析的音频分段>>>>>>>>', info);
			frequenciesRes = [];
			try {
				const detectPitch = Pitchfinder.YIN();
				for (let index in info) {
					let item = info[index];
					const _arrayBuffer = await window.disposeFile(item.path);
					// 在节点中查找 wav 文件的音调
					// const decoded = WavDecoder.decode.sync(arrayBuffer);
					const decoded = WavDecoder.decode.sync(_arrayBuffer);
					const float32Array = decoded.channelData[0];
					// 设置节奏和量化间隔，将返回每个间隔的音高数组。
					const frequencies = await new Promise((resolve) => {
						resolve(Pitchfinder.frequencies(detectPitch, float32Array, {
							tempo: tempo, // BPM中默认为120
							quantization: quantization, // 每拍采样，默认为4(即第16个音符)
						}));
					});

					// if ((info.length - 1) == index) this.frequenciesLoading = false;
					// this.frequencies = frequencies;
					frequenciesRes.push({
						shichang: item.section,
						value: frequencies,
					})
					// console.log('Pitchfinder.frequencies>>>>>>>>>', frequencies);
				}

				if (!!window.GetFrequencies) window.GetFrequencies.postMessage(JSON.stringify(frequenciesRes));
			} catch (error) {
				console.error(error);

				if (!!window.Toasts) window.Toasts.postMessage('开始解析音频分段...' + JSON.stringify(error));
			}
			
		};
	},
	methods: {
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 {
	margin: 40px 0 0; */
/* } */
.hello {
	display: none;
}

table {
	margin: 40px 0 0;
}

table th,
table td {
	padding: 0 10px;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}

.box {
	width: 900px;
	margin: auto;
	/* display: flex;
	flex-direction: row;
	justify-content: space-around; */
}

.box>div {
	flex: 1;
	padding: 0 20px;
}

.musicScore {
	width: 100%;
	outline: none;
	box-sizing: border-box;
}
</style>
