import axios from 'axios'
// axios.defaults.baseURL = '/app'
axios.defaults.baseURL = process.env.BASE_API

let host = 'http://yfdemo.59156.cn';

export const hostUrl = host;

axios.interceptors.request.use(
  config => {
		config.params = {}
		config.timeout = 300000
    return config;
  },
  err => {
    return Promise.reject(err);
  }
)

// 充值金币套餐提交订单
export const commonUpload = params => {
	return new Promise((resolve, reject) => {
		console.log('请求参数', params);
		axios.post(`${host}/api/common/upload`, params).then(res => resolve(res.data)).catch(err => reject(err));
  })
}
